import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import axios from 'axios';

import { Row, Col, DropdownButton, ButtonGroup, Dropdown, Modal, Button, InputGroup, Form } from 'react-bootstrap'

import './widget.css'

const Preview = () => {
    const id = useParams().id;

    const [post, setPost] = useState()

    const fetchFeed = async () => {
        try {
            const response = await axios.get(`https://gorillamediallc.com/bbgfeedserver/api/getfeedpost/${id}`)
            if(response.data.feed) {
                setPost(response.data.feed)
            } else {
                window.location.href = '/'
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchFeed()
    }, []);

    
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const [progress, setProgress] = useState(0);

    const handlePlayPause = () => {
        if (isPlaying) {
        videoRef.current.pause();
        } else {
        videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleFullscreen = () => {
        if (videoRef.current) {
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) {
            videoRef.current.mozRequestFullScreen();
        } else if (videoRef.current.webkitRequestFullscreen) {
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) {
            videoRef.current.msRequestFullscreen();
        }
        }
    };

    const handleMute = () => {
        videoRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
    };

    const handleProgress = (event) => {
        const totalWidth = event.target.clientWidth;
        const clickX = event.nativeEvent.offsetX;
        const newTime = (clickX / totalWidth) * videoRef.current.duration;
        videoRef.current.currentTime = newTime;
        setProgress((newTime / videoRef.current.duration) * 100);
    };

    const updateProgress = () => {
        if (videoRef.current) {
        const percent = (videoRef.current.currentTime / videoRef.current.duration) * 100;
        setProgress(percent);
        }
    };

    useEffect(() => {
        const videoElement = videoRef.current;
    
        if (videoElement) {
        videoElement.muted = true;
        videoElement.addEventListener('timeupdate', updateProgress);
        }
    
        return () => {
        if (videoElement) {
            videoElement.removeEventListener('timeupdate', updateProgress);
        }
        };
    }, [isPlaying]);


    // LINKS
    const copyLink = () => {
    const currentLink = window.location.origin+`/preview/${_id}`;

    navigator.clipboard.writeText(currentLink)
        .then(() => {
            alert('Link copied!');
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
        });
    };


     // EMBED
    const [showEmbed, setShowEmbed] = useState(false);

    const handleCloseEmbed = () => setShowEmbed(false);
    const handleShowEmbed = () => setShowEmbed(true);

    const copyLinkInput = (type) => {
        
        let input;
        if(type === 'fixed') {
        input = document.getElementById('fixed-frame')
        } else if(type === 'responsive') {
        input = document.getElementById('responsive-frame')
        }

        navigator.clipboard.writeText(input.value)
            .then(() => {
                alert('Link copied!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <>
        <Modal show={showEmbed} onHide={handleCloseEmbed} className="custom_adsmodal">
        <Modal.Header closeButton>
        <Modal.Title>Embed</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4>Fixed frame</h4>
        <InputGroup className="mb-3">
            <Form.Control
            id='fixed-frame'
            value={`<iframe src='${window.location.origin+`/preview/${id}`}' frameborder='0' scrolling='no' allowfullscreen width='1080' height='1920'></iframe><p><a href='${window.location.origin+`/preview/${id}`}'>via BBGifs</a></p>`}
            />
            <Button variant="outline-secondary" id="button-addon-fixed" onClick={() => copyLinkInput('fixed')}>
            COPY
            </Button>
        </InputGroup>

        <h4>Responsive frame:</h4>
        <InputGroup className="mb-3">
            <Form.Control
            id='responsive-frame'
            value={`<div style='position:relative;'><iframe src='${window.location.origin+`/preview/${id}`}' frameBorder='0' scrolling='no' width='100%' height='1000' allowFullScreen></iframe></div><p><a href='${window.location.origin+`/preview/${id}`}'>via BBGifs</a></p>`}
            />
            <Button variant="outline-secondary" id="button-addon-responsive" onClick={() => copyLinkInput('responsive')}>
            COPY
            </Button>
        </InputGroup>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEmbed}>
            Close
        </Button>
        </Modal.Footer>
        </Modal>

        {post && (
            <>
            <Helmet>
                <meta name="description" content={post.description} />
                
                <meta property="og:description" content={post.description} />
                <meta property="og:type" content="video.other" />
                <meta property="og:url" content={window.location.href} />

                <meta name="twitter:card" content="player" />
                <meta name="twitter:description" content={post.description} />
                <meta name="twitter:player" content={window.location.href} />
                <meta name="twitter:player:width" content="640" />
                <meta name="twitter:player:height" content="360" />
            </Helmet>


            <div className='widget-body'>
            <Row>
                <Col xl={12} xs={12}>
                <div className='video-body-preview position-relative'>
                    <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ maxHeight: '40px' }} alt="small logo" className="widget-logo" />

                    {!isPlaying && (
                        <>
                        <button onClick={handlePlayPause} className='widget-playbtn'>
                            <i className="fa-solid fa-play"></i>
                        </button>
                        </>
                    )}

                    <div id="video-container">
                        <video
                            ref={videoRef}
                            src={`https://gorillamediallc.com/bbgfeedserver/${post.video}`}
                            width='100%'
                            height='100%'
                            controls={false}
                            onClick={handlePlayPause}
                        />
                    </div>

                    <div className='progress-bar' onClick={handleProgress} style={{ cursor: 'pointer', height: '5px', background: '#2e2e2e' }}>
                        <div style={{ width: `${progress}%`, height: '100%', background: '#347aeb' }} />
                    </div>

                    <div className='video-options-widget'>
                        <button onClick={handleMute}>
                        {isMuted ? <i className="fa-solid fa-volume-mute"></i> : <i className="fa-solid fa-volume-up"></i>}
                        </button>
                        <button onClick={handleFullscreen}>
                        <i className="fa-solid fa-expand"></i>
                        </button>
                        <DropdownButton
                            as={ButtonGroup}
                            key='start'
                            id={`dropdown-button-drop-start`}
                            drop='start'
                            variant='secondary'
                            title={<span><i className="fa-solid fa-share-from-square"></i></span>} 
                            data-bs-theme="dark"
                            style={{ marginLeft: '-7px'}}
                        >
                            <Dropdown.Item eventKey="1" onClick={copyLink}>Copy Link</Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={handleShowEmbed}>Embed</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="3" href={`https://www.reddit.com/submit?title=${encodeURIComponent(post.description)}&url=${encodeURIComponent(window.location.href)}`} target='_blank'>Reddit</Dropdown.Item>
                            <Dropdown.Item eventKey="4" href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(post.description)}&url=${encodeURIComponent(window.location.href)}`} target='_blank'>X</Dropdown.Item>
                            <Dropdown.Item eventKey="5" onClick={copyLink}>Discord</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                </Col>
            </Row>
            </div>
            </>
        )}
        </>
    )
}

export default Preview;