import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Tabs, Tab, Row, Col, Form, Button } from 'react-bootstrap';

import axios from 'axios';
import FeedVideo from '../helpers/FeedVideo';

import LoadingSpinner from '../LoadingSpinner';

import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import FeedImage from '../helpers/FeedImage';

const DashboardFeed = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [feed, setFeed] = useState([])
    const [sort, setSort] = useState('latest')
    
    const [filterCategories, setFilterCategories] = useState([])
    const [filterChannels, setFilterChannels] = useState([])

    const [currentItems, setCurrentItems] = useState(5)
    
    const fetchFeed = async (sorting) => {
        setIsLoading(true)
        setSort(sorting)

        try {
            const response = await axios.post('https://gorillamediallc.com/bbgfeedserver/api/getfeed', {
                sort: sorting,
                categories: filterCategories,
                channels: filterChannels
            });
            setFeed(response.data.feed)
            
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.log(err)
        }
    }

    useEffect(() => {
        fetchFeed('latest')
    }, []);

    const handleSort = async (sorting) => {
        setCurrentItems(5)
        setSort(sorting)
        await fetchFeed(sorting)
    }

    const handleSelect = (eventKey) => {
        if (eventKey === 'feed-latest') {
            handleSort('latest');
        } else if (eventKey === 'feed-popular') {
            handleSort('popular');
        }
    };

    const [moreLoading, setMoreLoading] = useState(false)
    const loadMore = async () => {
        try {
            const response = await axios.post('https://gorillamediallc.com/bbgfeedserver/api/getfeedmore', {
                sort: sort,
                current: currentItems,
                categories: filterCategories,
                channels: filterChannels
            });
    
            if(response.data.feed && response.data.feed.length > 0) {
                setMoreLoading(true)

                const updatedList = feed.concat(response.data.feed)

                setTimeout(() => {
                    setFeed(updatedList)
                    setCurrentItems(response.data.end)
    
                    setMoreLoading(false)
                }, 2000);
            }
        } catch (err) {
            setMoreLoading(false)
            console.log(err)
        }
    }

    const scrollRef = useRef(null);

    const handleScroll = useCallback(() => {
        if (scrollRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;

            if (scrollTop + clientHeight >= scrollHeight - 5) {
                loadMore();
            }
        }
    }, [loadMore]);

    useEffect(() => {
        scrollRef.current = document.getElementById('scroll-container');

        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollRef.current) {
                scrollRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);
      
    // Search
    const animatedComponents = makeAnimated();
    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#111",
          borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
          borderColor: state.isFocused ? "DodgerBlue" : "DodgerBlue",
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            borderColor: state.isFocused ? "DodgerBlue" : "DodgerBlue",
            background: "black",
            color: "white"
          }
        }),
        option: (base, state) => ({
            "&:hover": {
                borderColor: state.isFocused ? "DodgerBlue" : "DodgerBlue",
                background: "black",
                color: "white"
              }    
        }),
        menu: base => ({
          ...base,
          borderRadius: 0,
          marginTop: 0
        }),
        menuList: (base, state) => ({
          ...base,
          background: "DodgerBlue",
          padding: "0.25rem",
        })
    };

    const [categoriesOptions, setCategoriesOptions] = useState([])
    const [channelsOptions, setChannelsOptions] = useState([])

    const handleChangeCategories = (data) => {
        setFilterCategories(data)
    }

    const handleChangeChannels = (data) => {
        setFilterChannels(data)
    }

    const handleGetCategories = async () => {
        try {
            const response = await fetch(`https://gorillamediallc.com/bbgfeedserver/api/getfeedcategories`, {
                method: 'GET'
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
    
            const data = await response.json();
    
            const formatCategoryOptions = data.categories.map((cat) => ({
                value: cat.name,
                label: cat.name
            }));
    
            setCategoriesOptions(formatCategoryOptions)
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    const handleGetChannels = async () => {
        try {
            const response = await fetch(`https://gorillamediallc.com/bbgfeedserver/api/getfeedchannels`, {
                method: 'GET'
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
    
            const data = await response.json();
    
            const formatChannelOptions = data.channels.map((chan) => ({
                value: chan.name,
                label: chan.name
            }));
    
            setChannelsOptions(formatChannelOptions)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        handleGetCategories()
        handleGetChannels()
    }, []);

    const handleFilters = async (e) => {
        e.preventDefault();

        await fetchFeed(sort)
    }

    return (
        <>
        <div className='position-relative'>    
        <div className='feed-filters'>
            <Form onSubmit={handleFilters}>
            <Row>
                <Col xl={5} xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Categories:</Form.Label>
                        <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        isMulti
                        options={categoriesOptions}
                        onChange={handleChangeCategories}
                        className="custom-reactselect"
                        styles={customStyles}
                        />
                    </Form.Group>
                </Col>
                <Col xl={5} xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Channels:</Form.Label>
                        <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        isMulti
                        options={channelsOptions}
                        onChange={handleChangeChannels}
                        className="custom-reactselect"
                        styles={customStyles}
                        />
                    </Form.Group>
                </Col>
                <Col xl={2} xs={12}>
                    <Button variant='dark' size='lg' type='submit' className='w-100' style={{ height: '70px' }}> <i className="fa-solid fa-magnifying-glass"></i> </Button>
                </Col>
            </Row>
            </Form>
        </div>

        <div
            id="scroll-container"
            ref={scrollRef}
        >
        <Tabs
        defaultActiveKey="feed-latest"
        id="uncontrolled-tab-example"
        className="m-2"
        onSelect={handleSelect}
        >
            <Tab eventKey="feed-latest" title="Latest">
                {!isLoading && sort && sort === 'latest' && feed && feed.length > 0 && feed.map((f, index) => {
                    return (
                        <React.Fragment key={`fl-${index}`}>
                            {f.type === 'video' ? <FeedVideo data={f} /> :  <FeedImage data={f} />}
                        </React.Fragment>
                    )
                })}
            </Tab>
            <Tab eventKey="feed-popular" title="Popular">
                {!isLoading && sort && sort === 'popular' && feed && feed.length > 0 && feed.map((f, index) => {
                    return (
                        <React.Fragment key={`fp-${index}`}>
                            {f.type === 'video' ? <FeedVideo data={f} /> :  <FeedImage data={f} />}
                        </React.Fragment>
                    )
                })}
            </Tab>
        </Tabs>
        </div>

        <center>
        {isLoading || moreLoading && <LoadingSpinner asOverlay={false} />}
        </center>

        </div>
        </>
    )
}

export default DashboardFeed;