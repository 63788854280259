import axios from "axios";

export const efImport = async (url, id) => {
    try {
      const res = await axios.post('https://gorillamediallc.com/bbgfeedserver/api/efimport', {
        url: url,
        id: id
      });
      if (res.data.message === "success") {
        console.log('success last');
      }
    } catch (err) {
      console.log("AXIOS ERROR: ", err);
    }
  };
  
  export const efUpload = async (url, id) => {
    try {
      const res = await axios.post('https://gorillamediallc.com/bbgfeedserver/api/efupload', {
        url: url,
        id: id
      });
      if (res.data.message === "success") {
        console.log('uploaded front');
        await efImport(res.data.url, res.data.id);
      }
    } catch (err) {
      console.log("AXIOS ERROR: ", err);
    }
  };
  
  export const runEverflow = async (efpid, sub1, ref, offer_id) => {
    try {
      const res = await axios.post('https://gorillamediallc.com/bbgfeedserver/api/efconvert', {
        ef: efpid,
        sub1: sub1,
        ref: ref,
        offer_id: offer_id
      });
      if (res.data.message === "success") {
        await efUpload(res.data.location, res.data.id);
      }
    } catch (err) {
      console.log("AXIOS ERROR: ", err);
    }
  };
  